import * as React from "react";
import { Container } from "react-bootstrap";
import CircularButton from "../components/circular-button";

const NotFoundSection: React.FC = () => (
  <section className="not-fund-section d-flex flex-column">
    <Container className="flex-fill d-flex flex-column justify-content-center align-items-center">
      <div className="not-fund-section__banner">
        <h1>Wybrana strona nie istnieje :(</h1>
        <div className="d-flex flex-md-row flex-column justify-content-center align-items-center mt-5 mt-md-0">
          <CircularButton
            id="selectLocalizationLinkButton"
            text="Znajdź działkę"
            className="white mx-3"
            link="/#localizationSection"
          ></CircularButton>
        </div>
      </div>
    </Container>
  </section>
);

export default NotFoundSection;
